.btn-pink {
    background-color: #66BB69;
    width: fit-content;
    color: white;
    padding: 0.7rem 2.1rem;
    box-shadow: 5px 5px 7px 0px #0000003f;
    font-size: 18px;
    cursor: pointer;
    transition: all 0.5s;
    font-weight: 500;
    border: solid 3px transparent;
    position: relative;
    z-index: 1;
  }
  .btn-pink::before {
    content: "";
    position: absolute;
    background-color:#2E7D31;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0px;
    
    transform: scaleX(0);
    transform-origin: left;
    transition: all 0.8s;
  }
  .btn-pink:hover::before {
    transform: scaleX(1);
  }
  .btn-pink:hover {
    border: solid 3px green;
    color:white;
    
  }

.contact{
    font-size:1.2rem;
    font-weight: 600;
    
}

.input-tag{
    
 
  border: 1px solid black;
  width:40rem;
  height: 2.3rem;
  border-radius: 4px;
  font-size: 1.3rem;
  padding-left: 10px;
  cursor:auto;
}
.input-tag-query{
    
 
  border: 1px solid black;
  width:40rem;
  height: 7rem;
  border-radius: 4px;
  font-size: 1.3rem;
  padding-left: 10px;
  cursor:auto;

}
textarea {
  
  resize: none;  /* Prevents resizing by the user */
}

@media(max-width:760px){
  .input-tag{
    width:20rem;
  }
  .input-tag-query{
    width: 20rem;
  }
  .top-container{
    padding-left: 1rem;
  }
}