*{
  font-family: 'Inter';
 
}
.intro-container{
  color: #4D4D4D;
}

.image-container {
    
    transition: transform 0.3s ease-in-out;
  }
  
  /* Hover effect */
.image-container:hover {
    transform: scale(1.1);
  }

  .btn-pink {
    background-color: #66BB69;
    width: fit-content;
    color: white;
    padding: 0.7rem 2.1rem;
    box-shadow: 5px 5px 7px 0px #0000003f;
    font-size: 18px;
    cursor: pointer;
    transition: all 0.5s;
    font-weight: 500;
    border: solid 3px transparent;
    position: relative;
    z-index: 1;
  }
  .btn-pink::before {
    content: "";
    position: absolute;
    background-color:#2E7D31;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0px;
    z-index: -1;
    transform: scaleX(0);
    transform-origin: left;
    transition: all 0.8s;
  }
  .btn-pink:hover::before {
    transform: scaleX(1);
  }
  .btn-pink:hover {
    border: solid 3px var(--bgOrange);
    color:white;
    
  }
  @media(max-width:700px){
    .intro-container{
      flex-direction: column;
      height: 52rem;
      
    }
    .image-container{
      padding: 0;
      width: 40vw;
      margin-left: 180px;
    }
    .intro_2{
      padding-top: 5rem;
    }
    .font_1{
      font-size: 3rem;
    }
    .font_2{
      font-size: 2.5rem;
    }
    

  }
