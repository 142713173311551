.footer-container{
    background-color: #263238;
    
}

p{
    font-size: 0.8rem;
}

@media(max-width:780px){
    .footer-container{
        flex-direction: column;
        height: 27rem;
        padding-top: 2.5rem;
    }
    .footer_1{
        margin-left: 0;
        margin-bottom: 2.1rem;
    }
    .footer_2{
        margin-bottom: 0;
    }
    .footer_3{
        margin-right: 0;
    }
}