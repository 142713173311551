@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
    margin:0;
    padding:0;
    box-sizing: border-box;
    
}

.navbar{
    position: fixed;
    background-color: white; /* Set background color to white */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow */
    z-index: 1000; /* Ensure the navbar is above other elements */
    
}

.web_navbar{
    list-style-type: none;
    display: flex;
    flex-direction: row;
    
    color: #4D4D4D;
    font-weight: 400;

   
}



.nav-item{
    cursor: pointer;
    transition: transform 0.1s ease-in;
    
}


.web_navbar:hover{
   
}

.nav-item:hover{
    color: black;
    transform: scale(1.1);
  }
  
  

.logo{
    width:7%;
    height:8vh;
    z-index: 1001;
}

.logo_text{
    color: #4D4D4D;
}

/* Mobile menu button */
.mobile-menu-button {
    focus:outline-none;
}

@media(max-width:780px){
    .logo{
        width:13%;
    }
    .nav-item{
        font-weight: bold;
        margin-top: 1rem;
        margin-bottom: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }
   

}
.mobile-menu{
    display: flex;
    justify-content: center;
    

}
